//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-800:_6444,_5316,_6252,_7644,_3316,_8668,_1636,_7328;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-800')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-800', "_6444,_5316,_6252,_7644,_3316,_8668,_1636,_7328");
        }
      }catch (ex) {
        console.error(ex);
      }